import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../../../../Assets/logo.png';

const DrawerHeader = () => (
  <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }} component={Link} to="/case">
    <img src={Logo} alt="Logo" style={{ width: '50%' }} />
  </Box>
);

export default DrawerHeader;
