import React from 'react'

import { Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = ({handleClick}) => {
  return (
    <Button
    variant="contained"
    color="error"
    onClick={handleClick}
    sx={{
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <LogoutIcon /> Logout
  </Button>
  )
}

export default LogoutButton