import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Tooltip } from '@mui/material';
import { channelOptions } from './options/channelOptions';

const ChannelSelect = ({ value, onChange, error }) => {
  return (
    <Tooltip title="Please select where the caller is calling from" placement="top">
      <FormControl variant="outlined" fullWidth error={error}>
        <InputLabel id="channel-label">Channel</InputLabel>
        <Select
          labelId="channel-label"
          id="channel"
          name="channel"
          value={value}
          onChange={onChange}
          label="Channel"
          error={error}
        >
          <MenuItem value="">
            <em>Select a channel</em>
          </MenuItem>
          {channelOptions.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default ChannelSelect;
