import { create } from "zustand";

export const useDataStore = create((set) => ({
  rsaCarDetails: null,
  pcdData: null,
  azureSearchResults: null,
  addressDetails: null,
  setRsaCarDetails: (data) => set({ rsaCarDetails: data }),
  setPcdData: (data) => set({ pcdData: data }),
  setAzureSearchResults: (data) => set({ azureSearchResults: data }),
  setAddressDetails: (data) => set({ addressDetails: data }),
  clearPcdData: () => set({ pcdData: null }),
  clearRsaData: () => set({ rsaCarDetails: null }),
  clearAddressDetails: () => set({ addressDetails: null }),
  clearAzureSearchResults: () => set({ azureSearchResults: null }),
}));

export const useSearchStore = create((set) => ({
  registrationNumber: "",
  selectedCountry: "NO",
  setRegistrationNumber: (number) => set({ registrationNumber: number }),
  setSelectedCountry: (country) => set({ selectedCountry: country }),
}));
