import { Button } from '@mui/material';
import React from 'react';

const OpenDialog = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      color="info"
      onClick={onClick}
      sx={{
        display: 'flex',
        gap: 2,
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'warning.main', 
        color: 'white', 
        '&:hover': {
          backgroundColor: 'warning.dark',
          boxShadow: 10,
        },
        boxShadow: 5,
      }}
    >
      More details
    </Button>
  );
};

export default OpenDialog;
