// CasePageForm.jsx
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useRegSearch from '../../hooks/Search/useRegSearch';
import BaseLayout from './CaseLayout/BaseLayout';
import SplitLayout from './CaseLayout/SplitLayout';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { useLayout } from '../../Context/LayoutContext';
import { useInitialValues } from '../../hooks/useInitialValues';
import { useValidationSchema } from '../../hooks/useValidationSchema';

const CasePageForm = ({ submitForm }) => {
  if (typeof submitForm !== 'function') {
    console.error('submitForm is not a function');
  }

  const { layout } = useLayout();

  const [openCase, setOpenCase] = useState(false);
  const [caseData, setCaseData] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [hasSearched, setHasSearched] = useState(false);

  const { validationSchema } = useValidationSchema('otherForm');
  const { initialValues } = useInitialValues('defaultForm');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (!values.dateTime) {
        values.dateTime = new Date().toISOString();
      }
      console.log('Formik values before submit:', values);
      submitForm(values);
      console.log('Form Submitted!', values);
    },
  });

  const { loading, pcdError, rsaError, fetchRegData } = useRegSearch();

  const handleInputChange = useCallback(
    (e) => {
      formik.handleChange(e);
    },
    [formik]
  );

  const handleBlur = useCallback(
    (e) => {
      formik.handleBlur(e);
    },
    [formik]
  );

  const handleChannelChange = (e) => {
    formik.setFieldValue('channel', e.target.value);
  };

  const handlePriorityChange = (e) => {
    formik.setFieldValue('priority', e.target.value);
  };

  const handleSearchResult = (result) => {
    setSearchResult(result);
    formik.setFieldValue('location.city', result.city);
    formik.setFieldValue('location.postalCode', result.postalCode);
    formik.setFieldValue('location.countryCode', result.countryCode);
    formik.setFieldValue('location.countryCodeISO', result.countryCodeISO);
    formik.setFieldValue('location.country', result.country);
    formik.setFieldValue('location.address', result.address);
    formik.setFieldValue('location.lon', result.position[0]);
    formik.setFieldValue('location.lat', result.position[1]);
  };

  const handleSearch = useCallback(() => {
    fetchRegData(formik.values.registrationNumber, formik.setValues, setCaseData, setOpenCase);
    setHasSearched(true);
  }, [formik.values.registrationNumber, fetchRegData, formik.setValues]);

  useEffect(() => {
    const storedData = localStorage.getItem('RSA_CAR_DETAILS');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setCaseData(parsedData);
    }
  }, []);

  const pcdErrorMessage = getErrorMessage(pcdError);
  const rsaErrorMessage = getErrorMessage(rsaError);

  useEffect(() => {
    if (pcdErrorMessage !== null) {
      console.log('pcdErrorMessage', pcdErrorMessage);
    }
    if (rsaErrorMessage !== null) {
      console.log('rsaErrorMessage', rsaErrorMessage);
    }
    if (formik.values) {
      console.log('Formik values', formik.values);
    }
    if (formik.errors) {
      console.log('Formik validation errors:', formik.errors);
    }
  }, [formik.errors, formik.values, rsaErrorMessage, pcdErrorMessage]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {layout === 'base' ? (
        <BaseLayout
          formik={formik}
          searchResult={searchResult}
          caseData={caseData}
          openCase={openCase}
          hasSearched={hasSearched}
          loading={loading}
          pcdErrorMessage={pcdErrorMessage}
          rsaErrorMessage={rsaErrorMessage}
          handleSearchResult={handleSearchResult}
          handleInputChange={handleInputChange}
          handleBlur={handleBlur}
          handleChannelChange={handleChannelChange}
          handlePriorityChange={handlePriorityChange}
          handleSearch={handleSearch} // Pass handleSearch here
        />
      ) : (
        <SplitLayout
          formik={formik}
          searchResult={searchResult}
          caseData={caseData}
          openCase={openCase}
          hasSearched={hasSearched}
          loading={loading}
          pcdErrorMessage={pcdErrorMessage}
          rsaErrorMessage={rsaErrorMessage}
          handleSearchResult={handleSearchResult}
          handleInputChange={handleInputChange}
          handleBlur={handleBlur}
          handleChannelChange={handleChannelChange}
          handlePriorityChange={handlePriorityChange}
          handleSearch={handleSearch} // Pass handleSearch here
        />
      )}
    </form>
  );
};

export default CasePageForm;
