import { useState, useCallback } from 'react';
import { getPcdData, getRsaData } from '../../api/apiRequests';

import {useAxiosInstance} from '../../api/axiosInstance'

const useRegSearch = () => {
  const [loading, setLoading] = useState(false);
  const [pcdError, setPcdError] = useState(null);
  const [rsaError, setRsaError] = useState(null); 

  const axiosInstance = useAxiosInstance()

  const fetchRegData = useCallback(
    async (registrationNumber, setFormikValues, setCaseData, setOpenCase) => {
      setLoading(true);
      setPcdError(null);
      setRsaError(null);

      let countryCode;

      try {
        countryCode = localStorage.getItem('regNumCountry');
        try {
          const pcdResponse = await getPcdData(axiosInstance, registrationNumber.toUpperCase(), countryCode);
          if (pcdResponse) {
            setFormikValues((prevValues) => ({
              ...prevValues,
              pcdData: {
                address: pcdResponse.Address || '',
                bodyType: pcdResponse['Body Type'] || '',
                city: pcdResponse.City || '',
                colour: pcdResponse.Colour || '',
                dateOfFirstRegistration: pcdResponse['Date of First Registration'] || '',
                fuelType: pcdResponse['Fuel Type'] || '',
                insuranceCompany: pcdResponse['Insurance Company'] || '',
                lastName: pcdResponse['Last Name'] || '',
                length: pcdResponse['Length'] || '',
                make: pcdResponse.Make || '',
                model: pcdResponse.Model || '',
                numberOfAxlesInOperation: pcdResponse['Number of Axles in Operation'] || '',
                organizationNumber: pcdResponse['Organization Number'] || '',
                registrationNumber: pcdResponse['Registration Number'] || '',
                totalNumberOfAxles: pcdResponse['Total Number of Axles'] || '',
                vin: pcdResponse.VIN || '',
                weight: pcdResponse.Weight || '',
                width: pcdResponse.Width || '',
                year: pcdResponse.Year || '',
                zip: pcdResponse.Zip || '',
              },
            }));
          }
        } catch (error) {
          setPcdError({ status: error.response?.status, message: error.message });
        }
        try {
          const rsaResponse = await getRsaData(axiosInstance, registrationNumber);
          if (rsaResponse) {
            const rsaCaseData = JSON.parse(rsaResponse);
            setCaseData(rsaCaseData);
            setOpenCase(rsaCaseData.length > 0);
          }
        } catch (error) {
          setRsaError({ status: error.response?.status, message: error.message });
        }
      } catch (error) {
        console.error('Unexpected error during search:', error);
      } finally {
        setLoading(false);
      }
    }, [axiosInstance]);

  return { loading, pcdError, rsaError, fetchRegData };
};

export default useRegSearch;
