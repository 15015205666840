import { Button } from '@mui/material'
import React from 'react'

const ViewMore = ({handleOnClick}) => {
  return (
    <Button onClick={handleOnClick} variant="contained">
    Load More
  </Button>
  )
}

export default ViewMore