import React, { useEffect, useState } from 'react';
import { FormControl, InputAdornment, MenuItem, TextField, Tooltip } from '@mui/material';
import { countryCodes } from './countryCodes';
import { validatePhoneNumber } from './phoneValidation';

const PhoneNumber = ({
  value,
  onChange,
  onBlur,
  error,
  touched,
  countryCode,
  onCountryCodeChange,
  sx,
}) => {
  const [filteredCountryCodes, setFilteredCountryCodes] = useState(countryCodes);
  const [searchQuery, setSearchQuery] = useState(''); // eslint-disable-line
  const [inputError, setInputError] = useState(''); 

  const handlePhoneChange = (e) => {
    const newValue = e.target.value;
    onChange(e);

    const validationError = validatePhoneNumber(newValue, countryCode);
    setInputError(validationError);
  };

  const handleCountryCodeChange = (e) => {
    const newCountryCode = e.target.value;
    onCountryCodeChange(newCountryCode);

    const validationError = validatePhoneNumber(value, newCountryCode);
    setInputError(validationError);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredCountryCodes(
      countryCodes.filter(
        (option) => option.name.toLowerCase().includes(query) || option.code.includes(query)
      )
    );
  };

  useEffect(() => {
    if (!countryCode) {
      onCountryCodeChange('+47');
    }
  }, [countryCode, onCountryCodeChange]);

  return (
    <Tooltip title='Number to person on site. The tower will call them on this number' placement='top'>
      <FormControl variant="outlined" fullWidth error={(error && touched) || !!inputError} sx={sx}>
        <TextField
          variant="outlined"
          placeholder="On site contact person (Phone number)"
          inputMode="tel"
          value={value}
          onChange={handlePhoneChange}
          onBlur={onBlur}
          error={(error && touched) || !!inputError}
          helperText={(touched && error) || inputError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextField
                  select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  variant="standard"
                  onInput={handleSearch}
                  InputProps={{
                    style: { width: 'auto' },
                  }}
                >
                  {filteredCountryCodes.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      ({option.name}) {option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
            inputProps: { maxLength: 15 },
          }}
          sx={{ flex: 1 }}
        />
      </FormControl>
    </Tooltip>
  );
};

export default PhoneNumber;
