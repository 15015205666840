export const flexBoxDefault = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  width: '100%',
  height: '100%',
};

export const flexBoxCol = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  mt: 2,
};

export const flexBoxRow = {
  display: 'flex',
  gap: 3,
  width: '100%',
  height: '100%',
};

export const flexWrapper = {
  display: 'flex',
  gap: 3,
  padding: 2,
};

export const flexInner = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const cardSx = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  width: 'Auto',
  boxShadow: 5,
  padding: 3,
  zIndex: '1000',
  display: 'flex',
  flexDirection: 'column',
};