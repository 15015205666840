import REDGORegular from './fonts/REDGORegular.woff2';

import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

export const tokens = (mode) => ({
  ...(mode === 'dark'
    ? {
        primary: {
          greenGo: '#2A3A3A',
        },
        secondary: {
          tealGreen: '#4C6B6B',
          dustyGreen: '#6F8F8F',
        },
        greyScale: {
          black: '#E0E0E0',
          dark: '#B0B0B0',
          darkMid: '#8F8F8F',
          lightMid: '#6F6F6F',
          light: '#4F4F4F',
          white: '#2C2C2C',
        },
        error: {
          errorRed: '#FF6F6F',
          lightErrorRed: '#FFB3B3',
        },
        background: {
          default: '#1E1E1E',
          paper: '#2A2A2A',
        },
        text: {
          primary: '#E0E0E0',
          secondary: '#B0B0B0',
          white: '#ffff'
        },
      }
    : {
        primary: {
          greenGo: '#125151',
        },
        secondary: {
          tealGreen: '#CFEEDE',
          dustyGreen: '#F7F9F5',
        },
        greyScale: {
          black: '#000000',
          dark: '#383636',
          darkMid: '#9A9A9A',
          lightMid: '#C4C4C4',
          light: '#F5F5F5',
          white: '#FFFFFF',
        },
        error: {
          errorRed: '#FF1E28',
          lightErrorRed: '#FFEBEC',
        },
        background: {
          default: '#FFFFFF',
          paper: '#F5F5F5',
        },
        text: {
          primary: '#000000',
          secondary: '#383636',
          white: '#ffff'
        },
      }),
});

export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      primary: {
        main: colors.primary.greenGo,
      },
      secondary: {
        main: colors.secondary.tealGreen,
        dark: colors.greyScale.dark,
        light: colors.secondary.dustyGreen,
      },
      error: {
        main: colors.error.errorRed,
        light: colors.error.lightErrorRed,
      },
      background: {
        default: colors.background.default,
        paper: colors.background.paper,
      },
      text: {
        primary: colors.text.primary,
        secondary: colors.text.secondary,
        white: colors.text.white
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: 'RedgoRegular, sans-serif',
      fontWeightBold: 700,
      h1: {
        color: colors.text.primary,
        fontSize: '2rem',
        fontWeight: 700,
      },
      h2: {
        color: colors.text.primary,
        fontSize: '1.5rem',
        fontWeight: 700,
      },
      h3: {
        color: colors.primary.greenGo,
        fontSize: '1.125rem',
        fontWeight: 700,
      },
      body1: {
        fontSize: '1rem',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'RedgoRegular';
            font-style: normal;
            font-display: swap;
            font-weight: 400 700;
            src: local('../fonts/REDGORegular'), local('../fonts/REDGORegular'), url(${REDGORegular}) format('woff2');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '0.5rem 2rem',
            border: '1px solid transparent',
            backgroundColor: colors.primary.greenGo,
            color: colors.greyScale.white,
            borderRadius: '0.5rem',
            fontWeight: 700,
            fontSize: '1rem',
          },
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              backgroundColor: colors.primary.greenGo,
              color: colors.greyScale.white,
            },
          },
          {
            props: { variant: 'secondary' },
            style: {
              backgroundColor: colors.secondary.tealGreen,
              color: colors.greyScale.dark,
              '&:disabled': {
                backgroundColor: colors.greyScale.lightMid,
                color: colors.greyScale.black,
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              backgroundColor: colors.greyScale.white,
              color: colors.primary.greenGo,
            },
          },
        ],
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: colors.background.default,
            boxShadow: 'none',
            padding: '0.5rem',
            borderRadius: '0.5rem',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: colors.greyScale.light,
          },
          input: {
            '&::placeholder': {
              color: colors.greyScale.white,
            },
            '& textarea': {
              color: colors.text.primary,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colors.text.primary,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: colors.greyScale.white,
            '&.Mui-error': {
              backgroundColor: colors.error.lightErrorRed,
            },
            '& input': {
              color: 'black',
            },
            '&.Mui-disabled': {
              backgroundColor: colors.greyScale.white,
              color: colors.text.primary,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            backgroundColor: colors.greyScale.light,
            color: colors.greyScale.dark,
            borderRadius: '2rem',
            border: '1px solid transparent',
            '&:hover': {
              backgroundColor: colors.greyScale.lightMid,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: colors.secondary.tealGreen,
              color: colors.greyScale.dark,
              fontWeight: 600,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            margin: '0.5rem',
            padding: '0.5rem',
            '&:not(:last-of-type)': {
              borderRadius: '1rem',
            },
            '&:not(:first-of-type)': {
              borderRadius: '1rem',
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '&.MuiStepIcon-root': {
              color: colors.greyScale.darkMid,
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: '0.5',
            },
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: colors.primary.greenGo,
            color: colors.greyScale.white,
            fontSize: '0.75rem',
            borderRadius: '0.25rem',
          },
          arrow: {
            color: colors.primary.greenGo,
          },
        },
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('appTheme');
    return savedMode || 'light';
  });
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === 'light' ? 'dark' : 'light';
          localStorage.setItem('appTheme', newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
