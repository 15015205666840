import { useCallback } from 'react';
import { searchAzureMaps, reverseGeocode } from '../api/mapRequests';
import { useAxiosInstance } from '../api/axiosInstance';

const useSearchHandler = (inputMode, setSearchResults, setAnchorEl, onSearchResult) => {
  const axios = useAxiosInstance();
  const handleSearch = useCallback(
    async (values) => {
      if (inputMode === 'search') {
        const searchTerm = values.searchTerm.trim();
        if (!searchTerm) return;

        try {
          const results = await searchAzureMaps(axios, searchTerm);
          setSearchResults(results);
          setAnchorEl(null);
        } catch (error) {
          console.error('Error during search:', error);
          setSearchResults([]);
        }
      } else if (inputMode === 'coordinates') {
        const coordinates = values.coordinates.split(',').map((coord) => coord.trim());
        const [lat, lon] = coordinates.map(Number);

        if (isNaN(lat) || isNaN(lon)) {
          console.error('Invalid coordinates');
          return;
        }

        try {
          const result = await reverseGeocode(axios, [lon, lat]);
          if (result) {
            onSearchResult({
              position: [lon, lat],
              ...result,
            });
            setSearchResults([{ lat, lon, address: result.address }]);
          } else {
            setSearchResults([]);
          }
        } catch (error) {
          console.error('Error during coordinate search:', error);
          setSearchResults([]);
        }
      }
    },
    [inputMode, setSearchResults, setAnchorEl, onSearchResult, axios]
  );

  const handleSearchResultClick = useCallback(
    (result) => {
      if (!result) {
        console.error('Result is undefined or null');
        return;
      }

      let position;

      if (
        result.position &&
        result.position.lon !== undefined &&
        result.position.lat !== undefined
      ) {
        position = [result.position.lon, result.position.lat];
      } else if (result.lon !== undefined && result.lat !== undefined) {
        position = [result.lon, result.lat];
      } else {
        console.error('Invalid result format:', result);
        return;
      }

      onSearchResult({
        position,
        title: result.poi ? result.poi.name : 'Location Found',
        address: result.address ? result.address.freeformAddress : 'Unknown Address',
        postalCode: result.address ? result.address.postalCode : 'Unknown Postal Code',
        countryCode: result.address ? result.address.countryCode : 'Unknown Country Code',
        countryCodeISO: result.address ? result.address.countryCodeISO3 : 'Unknown ISO Code',
        country: result.address ? result.address.country : 'Unknown Country',
        city: result.address
          ? result.address.municipality || result.address.localName
          : 'Unknown City',
      });
      setSearchResults([]);
    },
    [onSearchResult, setSearchResults]
  );

  return { handleSearch, handleSearchResultClick };
};

export default useSearchHandler;
