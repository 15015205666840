import React, { useEffect, useRef, useState } from 'react';
import atlas, { HtmlMarker } from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import '../../Styles/AzureMaps.css'
import SearchResultCard from './Props/SearchResultCard';
import { reverseGeocode } from '../../api/mapRequests';
import { useAxiosInstance } from '../../api/axiosInstance';

const MapComponent = ({ searchResult, formikValues }) => {
  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [cardData, setCardData] = useState(null);
  const subscriptionKey = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY;
  const axios = useAxiosInstance();

  useEffect(() => {
    let map;
    if (mapRef.current) {
      map = new atlas.Map(mapRef.current, {
        center: [10.75, 59.91],
        zoom: 10,
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey,
        },
      });

      map.events.add('ready', () => {
        setMapInstance(map);

        if (searchResult) {
          const position = [searchResult.position[0], searchResult.position[1]];
          const marker = createMarker(map, position);
          map.markers.add(marker);
          map.setCamera({ center: position, zoom: 14 });
          setCardData({ position, address: searchResult.address, title: searchResult.title });
        }

        map.events.add('contextmenu', handleMapRightClick);
      });
    }

    return () => mapInstance && mapInstance.dispose();
  }, [searchResult, subscriptionKey]); // eslint-disable-line

  const handleMapRightClick = async (e) => {
    const position = e.position ? [e.position[0], e.position[1]] : null;
    if (position) {
      const marker = createMarker(mapInstance, position);
      mapInstance.markers.add(marker);
      mapInstance.setCamera({ center: position, zoom: 14 });

      const addressDetails = await reverseGeocode(axios, position);
      if (addressDetails) {
        setCardData({ position, address: addressDetails.address, title: addressDetails.title });
      }
    }
  };

  const createMarker = (map, position) => {
    const marker = new HtmlMarker({
      position,
      htmlContent: '<div><div class="pin bounce"></div><div class="pulse"></div></div>',
      draggable: true,
    });

    map.events.add('dragend', marker, async (e) => {
      const newPosition = e.target.getOptions().position;
      const addressDetails = await reverseGeocode(axios, newPosition);
      if (addressDetails) {
        setCardData({
          position: newPosition,
          address: addressDetails.address,
          title: addressDetails.title,
        });
      }
    });

    return marker;
  };

  useEffect(() => {
    if (!mapInstance || (!formikValues.lat && !formikValues.lon)) return;

    const currentMarker = mapInstance.markers.getMarkers().find((m) => m instanceof HtmlMarker);
    const currentPosition = currentMarker ? currentMarker.getOptions().position : null;
    const newPosition = [formikValues.lon, formikValues.lat];
    const positionChanged =
      !currentPosition ||
      currentPosition[0] !== newPosition[0] ||
      currentPosition[1] !== newPosition[1];

    if (positionChanged) {
      mapInstance.markers.clear();
      if (formikValues.lat && formikValues.lon) {
        const marker = createMarker(mapInstance, newPosition);
        mapInstance.markers.add(marker);
        mapInstance.setCamera({ center: newPosition, zoom: 14 });
        setCardData({
          position: newPosition,
          address: formikValues.address,
          title: formikValues.title,
        });
      }
    }
  }, [formikValues.lat, formikValues.lon, mapInstance]); // eslint-disable-line

  const closeCard = () => setCardData(null);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
      {cardData && (
        <SearchResultCard
          address={cardData.address || 'No Address Available'}
          position={cardData.position || [0, 0]}
          companyName={cardData.title || 'Location Found'}
          onClose={closeCard}
        />
      )}
    </div>
  );
};

export default MapComponent;
