import { Alert } from '@mui/material';
import React from 'react';
import RsaLink from '../../../Link/RsaLink';

const MissingFieldError = ({ label, id }) => {
  return (
    <Alert variant="filled" severity="error" sx={{ margin: 0 }}>
      {`${label} is required. Set in `}
      <RsaLink id={id}/>
    </Alert>
  );
};

export default MissingFieldError;
