import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styled } from '@mui/system';

const StyledAccordion = styled(Accordion)(({ success, error }) => ({
  border: success ? '2px solid green' : error ? '2px solid red' : '1px solid rgba(0, 0, 0, .125)',
  marginBottom: '16px',
}));

const CustomAccordion = ({
  title,
  fields,
  formik,
  defaultExpanded = false,
  children,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    let hasError = false;
    let hasMissingValue = false;

    fields.forEach(field => {
      if (formik.errors[field] && formik.touched[field]) {
        hasError = true;
      }
      if (!formik.values[field]) {
        hasMissingValue = true;
      }
    });

    setIsError(hasError || hasMissingValue);
    setIsSuccess(!hasError && !hasMissingValue);
  }, [formik.errors, formik.touched, formik.values, fields]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleChange}
      success={isSuccess}
      error={isError}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        <Typography>{title}</Typography>
        <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
          {isSuccess && <CheckCircleIcon color="success" />}
          {isError && <WarningAmberIcon color="error" />}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default CustomAccordion;
