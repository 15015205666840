import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const DatePicker = ({ formikValue, setFormikValue }) => {
  const handleDateTimeChange = (newValue) => {
    if (newValue) {
      const isoString = newValue.toISOString();
      return isoString;
    } else {
      setFormikValue(null);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(params) => <TextField {...params} />}
          value={formikValue ? dayjs(formikValue) : null}
          onChange={handleDateTimeChange}
          label="Select Date and Time"
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePicker;
