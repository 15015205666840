import { countryToCurrency } from '../utils/Mapping/countryToCurrency';
import { replaceEmptyStringsWithNull } from '../utils/replaceEmptyStringsWithNull';

export const buildRequestBody = (values) => {
  replaceEmptyStringsWithNull(values);

  const currentDateTime = new Date().toISOString();
  const isCurrentDateTime = currentDateTime.split('T')[0] === values.dateTime.split('T')[0];

  if (isCurrentDateTime) {
    values.dateTime = values.dateTime.split('T')[0];
  }

  if (values.location.lat && values.location.lon) {
    values.location.lat = values.location.lat.toString();
    values.location.lon = values.location.lon.toString();
  }

  const countryCode = values.location.countryCodeISO;
  if (countryToCurrency[countryCode]) {
    values.CurrencyISOCode = countryToCurrency[countryCode];
  }

  const body = {
    registrationNumber: values.registrationNumber,
    countryCode: values.location.countryCode,
    caseType: '012w00000006iZTAAY',
    currencyISOCode: values.CurrencyISOCode,
    caseDescription: values?.stationMessage,
    incidentDescription: values.coverCause,
    priority: values.priority,
    incidentDate: null,
    desiredAssistanceFrom: null,
    accountId: null,
    pcdDetail: {
      vin: values.pcdData.vin,
      make: values.pcdData.make,
      model: values.pcdData.model,
      type: values.pcdData.bodyType,
      insuranceCompany: values.pcdData.insuranceCompany,
      carProducedYear: values.pcdData.year,
      zip: null,
      city: null,
      address: null,
      firstName: null,
      lastName: null,
    },
    coverageDetail: {
      coveragePrimaryId: 'a017T000003Y6K5QAK',
    },
    onSiteContact: {
      name: values.onSiteContact.contactName,
      phoneNumber: values.onSiteContact.countryCode + values.onSiteContact.phone,
    },
    caseLocation: {
      address: values.location.address,
      zip: values.location.postalCode,
      city: values.location.city,
      country: values.location.country,
      latitude: values.location.lat,
      longitude: values.location.lon,
      description: values.location.locationMessage,
    },
    agent: values.agent,
  };

  return body;
};
