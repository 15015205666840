import React from 'react';
import { Button, Tooltip } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useSidebar } from '../../Context/SidebarContext';
import { iconButtonSx } from './IconButtonSx';

const OpenSideBar = () => {
  const { toggleSidebar } = useSidebar();

  return (
    <Tooltip title="Open sidebar">
      <Button onClick={toggleSidebar} variant="contained" sx={iconButtonSx(46, 46)}>
        <MenuOpenIcon />
      </Button>
    </Tooltip>
  );
};

export default OpenSideBar;
