import React from 'react';
import { Typography } from '@mui/material';
import BoxWrapper from '../components/UI/Layout/FlexBox/BoxWrapper';

const pageMissing = () => {
  return (
    <BoxWrapper
      sx={{
        width: '100%',
        height: '99%',
        marginTop: '30%',
        borderRadius: 3,
        boxShadow: 10,
      }}
      content={
        <>
          <Typography variant="h1" color="error">
            404 error
          </Typography>
          <Typography variant="body" color="error">
            Looks like you took a wrong turn and ended in the ditch. Call 02222 and get some help
          </Typography>
        </>
      }
    />
  );
};

export default pageMissing;
