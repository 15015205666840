import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import TextFieldWithValidation from '../../components/FormFields/TextRendering/TextFieldComponent';
import CustomAccordion from '../../components/Accordion/CustomAccordion';
import ReviewFormButton from '../../components/Button/ReviewFormButton';
import { useValidationSchema } from '../../hooks/useValidationSchema';
import { useInitialValues } from '../../hooks/useInitialValues';
import RsaLink from '../../components/Link/RsaLink';

const ReviewPageForm = ({ caseData }) => {
  const defaultCaseData = {
    id: '',
    registrationNumber: '',
    priority: '',
    location: {},
    vehicle: {},
    account: {},
    contact: {},
    coverCause: '',
    caseComment: '',
    coverage: '',
    station: {},
    workshop: {},
  };

  const { validationSchema } = useValidationSchema('reviewPage');
  const mergedCaseData = { ...defaultCaseData, ...caseData };
  const { initialValues } = useInitialValues('mergedCaseForm', mergedCaseData);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Form values:', values);
    },
  });

  return (
    <>
      <Box>
        <Typography variant="h2">Inform the customer</Typography>
        <br />
        <Typography variant="body1">
          Thank you for choosing Redgo, your tower is on the way.
        </Typography>
        <Typography variant="body1">
          Your car will be towed to {formik.values.workShopName}.
        </Typography>
        <Typography variant="body1">
          if you have any additional questions or want to know the status of your case, do not
          hassitate to call back.
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        {/* Customer Accordion */}
        <CustomAccordion
          title="Customer"
          fields={['customerName', 'ownerName']}
          formik={formik}
          defaultExpanded={formik.errors.customerName || formik.errors.ownerName}
        >
          <TextFieldWithValidation
            name="customerName"
            label="Customer Name"
            value={formik.values.customerName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.customerName && Boolean(formik.errors.customerName)}
            helperText={formik.touched.customerName && formik.errors.customerName}
          />
          <TextFieldWithValidation
            name="customerNumber"
            label="Customer Number"
            value={formik.values.customerNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.customerNumber && Boolean(formik.errors.customerNumber)}
            helperText={formik.touched.customerNumber && formik.errors.customerNumber}
          />
          {!formik.values.ownerName ? (
            <Typography color="error">Account is missing. Set in <RsaLink id={formik.values.id} color={true}/></Typography>
          ) : (
            <>
              <TextFieldWithValidation
                name="ownerName"
                label="Owner Name"
                value={formik.values.ownerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
                helperText={formik.touched.ownerName && formik.errors.ownerName}
              />
              <TextFieldWithValidation
                name="ownerBillingAddress"
                label="Billing Address"
                value={
                  `${formik.values.ownerBillingAddress ? formik.values.ownerBillingAddress + ', ' : ''}` +
                  `${formik.values.ownerPostalCode ? formik.values.ownerPostalCode + ' ' : ''}` +
                  formik.values.ownerBillingCity
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ownerBillingAddress && Boolean(formik.errors.ownerBillingAddress)
                }
                helperText={formik.touched.ownerBillingAddress && formik.errors.ownerBillingAddress}
              />
            </>
          )}
        </CustomAccordion>

        {/* Vehicle Details Accordion */}
        <CustomAccordion
          title="Vehicle Details"
          fields={['carModel', 'registrationNumber']}
          formik={formik}
          defaultExpanded={formik.errors.carModel || formik.errors.registrationNumber}
        >
          <TextFieldWithValidation
            name="carModel"
            label="Car Model"
            value={formik.values.carModel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.carModel && Boolean(formik.errors.carModel)}
            helperText={formik.touched.carModel && formik.errors.carModel}
          />
          <TextFieldWithValidation
            name="registrationNumber"
            label="Registration Number"
            value={formik.values.registrationNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.registrationNumber && Boolean(formik.errors.registrationNumber)}
            helperText={formik.touched.registrationNumber && formik.errors.registrationNumber}
          />
        </CustomAccordion>

        {/* Agreement | Coverage Accordion */}
        <CustomAccordion
          title="Agreement | Coverage"
          fields={['insuranceCompany', 'coverage', 'coverCause']}
          formik={formik}
          defaultExpanded={
            formik.errors.insuranceCompany || formik.errors.coverage || formik.errors.coverCause
          }
        >
          <TextFieldWithValidation
            name="insuranceCompany"
            label="Insurance Company"
            value={formik.values.insuranceCompany}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.insuranceCompany && Boolean(formik.errors.insuranceCompany)}
            helperText={formik.touched.insuranceCompany && formik.errors.insuranceCompany}
          />
          <TextFieldWithValidation
            name="coverage"
            label="Coverage"
            value={formik.values.coverage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.coverage && Boolean(formik.errors.coverage)}
            helperText={formik.touched.coverage && formik.errors.coverage}
          />
          <TextFieldWithValidation
            name="coverCause"
            label="Cover Cause"
            value={formik.values.coverCause}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.coverCause && Boolean(formik.errors.coverCause)}
            helperText={formik.touched.coverCause && formik.errors.coverCause}
          />
        </CustomAccordion>

        {/* Location Accordion */}
        <CustomAccordion
          title="Location"
          fields={['location', 'locationMessage']}
          formik={formik}
          defaultExpanded={formik.errors.location || formik.errors.locationMessage}
        >
          <TextFieldWithValidation
            name="location"
            label="Location"
            value={formik.values.location}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
          <TextFieldWithValidation
            name="locationMessage"
            label="Location Message"
            value={formik.values.locationMessage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.locationMessage && Boolean(formik.errors.locationMessage)}
            helperText={formik.touched.locationMessage && formik.errors.locationMessage}
          />
        </CustomAccordion>

        {/* Station Accordion */}
        <CustomAccordion
          title="Station"
          fields={['station', 'stationMessage']}
          formik={formik}
          defaultExpanded={formik.errors.station || formik.errors.stationMessage}
        >
          <TextFieldWithValidation
            name="station"
            label="Station"
            value={formik.values.station}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.station && Boolean(formik.errors.station)}
            helperText={formik.touched.station && formik.errors.station}
          />
          <TextFieldWithValidation
            name="stationMessage"
            label="Station Message"
            value={formik.values.stationMessage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.stationMessage && Boolean(formik.errors.stationMessage)}
            helperText={formik.touched.stationMessage && formik.errors.stationMessage}
          />
        </CustomAccordion>

        {/* Workshop Accordion */}
        <CustomAccordion
          title="Workshop"
          fields={['workShopName', 'workShopAddress']}
          formik={formik}
          defaultExpanded={formik.errors.workShopName || formik.errors.workShopAddress}
        >
          <TextFieldWithValidation
            name="workShopName"
            label="Workshop Name"
            value={formik.values.workShopName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.workShopName && Boolean(formik.errors.workShopName)}
            helperText={formik.touched.workShopName && formik.errors.workShopName}
          />
          <TextFieldWithValidation
            name="workShopAddress"
            label="Workshop Address"
            value={formik.values.workShopAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.workShopAddress && Boolean(formik.errors.workShopAddress)}
            helperText={formik.touched.workShopAddress && formik.errors.workShopAddress}
          />
        </CustomAccordion>

        <Box sx={{ mt: 2 }}>
          <ReviewFormButton type="submit">Submit</ReviewFormButton>
        </Box>
      </form>
    </>
  );
};

export default ReviewPageForm;
