export const channelOptions = [
  { id: 1, name: 'Gjensidige', coverId: 'Gjensidige_veihjelp' },
  { id: 2, name: 'Nissan', coverId: 'Nissan' },
  { id: 3, name: 'Mekonomen', coverId: 'Mekonomen' },
  { id: 4, name: 'JBF', coverId: 'JBF' },
  { id: 5, name: 'Meca', coverId: 'Meca' },
  { id: 6, name: 'Arval', coverId: 'Arval' },
  { id: 7, name: 'Frende', coverId: 'Frende' },
  { id: 8, name: 'SnapDrive', coverId: 'SnapDrive' },
  { id: 9, name: 'Eika', coverId: 'Eika' },
  { id: 10, name: 'Kia', coverId: 'Kia' },
  { id: 11, name: 'Landkredittforsikring', coverId: 'Landkredittforsikring' },
  { id: 12, name: 'Evoli', coverId: 'Evoli' },
  { id: 13, name: 'Pirelli', coverId: 'Pirelli' },
  { id: 14, name: 'Dackteam', coverId: 'Dackteam' },
  { id: 15, name: 'Bridgestone', coverId: 'Bridgestone' },
  { id: 16, name: 'Autollecom', coverId: 'Autollecom' },
  { id: 17, name: 'Drivalialease', coverId: 'Drivalialease' },
  { id: 18, name: 'Renault', coverId: 'Renault' },
  { id: 19, name: 'Bestcaravan', coverId: 'Bestcaravan' },
  { id: 20, name: 'REDGO', coverId: 'REDGO' },
];