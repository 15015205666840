import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const RsaLink = ({ id, color }) => {
  const theme = useTheme();

  return (
    <Link
      href={`https://redgoeurope--qa.sandbox.lightning.force.com/lightning/r/Case/${id}/view`}
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
      sx={{
        color: color ? theme.palette.error.main : theme.palette.text.white,
      }}
    >
      Salesforce
    </Link>
  );
};

export default RsaLink;
