import { useDataStore } from '../hooks/store/useDataStore';

const countrySet = 'no';

export const searchAzureMaps = async (axiosInstance, searchTerm) => {
  try {
    useDataStore.getState().clearAzureSearchResults();

    const response = await axiosInstance.get(process.env.REACT_APP_AZURE_MAP_FUZZY, {
      params: {
        'api-version': '1.0',
        countrySet,
        query: searchTerm,
        'subscription-key': process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY,
      },
    });

    const results = response.data.results || [];
    useDataStore.getState().setAzureSearchResults(results);

    return results;
  } catch (error) {
    if (error instanceof Error) {
      console.error('API call failed:', error.message);
      throw new Error(error.message || 'Failed to fetch MAP data');
    } else {
      console.error('Unknown error:', error);
      throw new Error('Failed to fetch MAP data');
    }
  }
};

export const reverseGeocode = async (axiosInstance, position) => {
  try {
    useDataStore.getState().clearAddressDetails();

    const response = await axiosInstance.get(process.env.REACT_APP_AZURE_MAP_REVERSE, {
      params: {
        'api-version': '1.0',
        query: `${position[0]},${position[1]}`,
        'subscription-key': process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY,
      },
    });

    const data = response.data;

    if (data?.addresses && data.addresses.length > 0) {
      const addressDetails = data.addresses[0].address;
      const result = {
        address: addressDetails.freeformAddress || 'No address found',
        city:
          addressDetails.municipality || addressDetails.municipalitySubdivision || 'Unknown City',
        postalCode: addressDetails.postalCode || 'Unknown postal code',
        country: addressDetails.country || 'Unknown Country',
        countryISO: addressDetails.countryCodeISO3 || 'Unknown ISO',
        countryCode: addressDetails.countryCode || 'Unknown country code',
      };

      useDataStore.getState().setAddressDetails(result);

      return result;
    } else {
      console.error('No address found for the given position');
      return null;
    }
  } catch (error) {
    console.error('Error during reverse geocoding:', error);
    return null;
  }
};

export const fetchPOI = async (axiosInstance, searchTerm) => {
  const response = await axiosInstance.get(process.env.REACT_APP_AZURE_MAP_POI, {
    params: {
      apiKey: process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY,
      query: searchTerm,
    },
  });
  return response.data.results[0];
};
