import { Box, Card } from '@mui/material';
import CarContainer from '../../../Container/CarContainer';

const CarContainerCard = ({ data }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 2,
        p: 1,
      }}
      component={Card}
    >
      <CarContainer data={data} />
    </Box>
  );
};

export default CarContainerCard;
