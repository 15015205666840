import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Box, Button, Card, Typography } from '@mui/material';
import { loginRequest } from '../auth/authConfig';
import LeftSidebar from '../components/UI/Layout/Sidebars/LeftSideBar/LeftSideBar';
import BgImg from '../Assets/truck.jpg';

const SignIn = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginPopup({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.error(error));
  };

  const LoginButton = () => (
    <Button variant="contained" color="secondary" onClick={handleRedirect}>
      Sign in
    </Button>
  );

  return (
    <div
      style={{
        display: 'block',
        backgroundImage: `url(${BgImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <AuthenticatedTemplate>
        {' '}
        {activeAccount ? (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              height: '100%',
            }}
          >
            <LeftSidebar />
          </Box>
        ) : null}{' '}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            alignContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
                padding: 5,
                boxShadow: 6,
                borderRadius: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
              }}
              component={Card}
            >
              <Typography variant="h4" component={'h1'}>
                Please sign in
              </Typography>{' '}
              <LoginButton />
            </Box>{' '}
          </Box>{' '}
        </Box>{' '}
      </UnauthenticatedTemplate>{' '}
    </div>
  );
};

export default SignIn;
