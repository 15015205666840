import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import SignIn from './pages/SignInPage';
import CasePage from './pages/case-page/CasePage';
import ReviewPage from './pages/review-page/ReviewPage';
import PageMissing from './pages/PageMissing';
import './Styles/App.css';
import { ColorModeContext, useMode } from './Styles/Theme';
import PageLayout from './components/UI/Layout/PageLayout';
import { LayoutProvider } from './Context/LayoutContext';
import { SidebarProvider } from './Context/SidebarContext';

const MainContent = ({ children }) => {
  return (
    <Container maxWidth={'xl'}>
      <Box>{children}</Box>
    </Container>
  );
};

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LayoutProvider>
          <SidebarProvider>
            <Router>
              <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route
                  path="/*"
                  element={
                    <MainContent>
                      <Routes>
                        <Route path="/" element={<PageLayout />}>
                          <Route path="case" element={<CasePage />} />
                          <Route path="review" element={<ReviewPage digital={false} />} />
                          <Route path="review/:id" element={<ReviewPage digital={false} />} />
                          <Route
                            path="review/digital/:id"
                            element={<ReviewPage digital={true} />}
                          />
                          <Route path="*" element={<PageMissing />} />
                        </Route>
                      </Routes>
                    </MainContent>
                  }
                />
              </Routes>
            </Router>
          </SidebarProvider>
        </LayoutProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
