import { Box, Alert } from '@mui/material';
import CaseList from './CaseList';
import CarContainerCard from './CarContainerCard';
import NoCaseSuccess from '../CaseStatus/NoCaseSuccsess';
import LoadingCar from '../../../Loading/LoadingCar';

const SearchResultsDisplay = ({
  loading,
  pcdLoading,
  caseLoading,
  hasSearched,
  pcdData,
  pcdErrorMessage,
  rsaErrorMessage,
  openCase,
  caseData,
}) => {
  const hasValidPCDData = (data) => {
    return data && data.vin && data.vin.trim() !== '';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {loading && openCase && caseLoading && <LoadingCar />}
      {pcdLoading && !pcdErrorMessage && !hasSearched && <LoadingCar />}
      {pcdErrorMessage && (
        <Alert severity="error" variant="filled">
          PCD data is missing or incomplete. Please try again.
        </Alert>
      )}
      {hasSearched && !pcdErrorMessage && !pcdLoading && hasValidPCDData(pcdData) && (
        <CarContainerCard data={pcdData} />
      )}
      {rsaErrorMessage ? (
        <Alert severity="error">{rsaErrorMessage}</Alert>
      ) : (
        openCase &&
        !caseLoading &&
        (caseData && caseData.length > 0 ? <CaseList caseData={caseData} /> : <NoCaseSuccess />)
      )}
    </Box>
  );
};

export default SearchResultsDisplay;
