import React, { useState } from 'react';
import { Box, Drawer, List } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ListItem from './StyledListItem';
import LogOutDialog from '../../../../Dialog/LogOutDialog';
import ThemeSwitch from '../../../../Button/ThemeSwitch';

import AssignmentIcon from '@mui/icons-material/Assignment';
import ReviewIcon from '@mui/icons-material/RateReview';
import DrawerHeader from './DrawerHeader';
import LayoutSwtich from '../../../../Button/LayoutSwtich';
import LogoutButton from '../../../../Button/LogoutButton';

const drawerWidth = 200;

const LeftSidebar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'inherit',
          boxShadow: 5,
          overflow: 'visible',
        },
      }}
    >
      <Box sx={{ overflow: 'visible' }}>
        <DrawerHeader />
        <List component="nav">
          <ListItem
            to="/case"
            icon={AssignmentIcon}
            primary="Case"
            active={location.pathname === '/case'}
          />
          <ListItem
            to="/review"
            icon={ReviewIcon}
            primary="Review"
            active={location.pathname.startsWith('/review')}
          />
        </List>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              gap: 2,
            }}
          >
            <LayoutSwtich />
            <ThemeSwitch />
          </Box>
          <LogoutButton handleClick={handleClickOpen} />
          <LogOutDialog open={open} handleClose={handleClose} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default LeftSidebar;
