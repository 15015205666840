export const xmlConverter = (xmlData) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlData, 'application/xml');
  const vehicleData = {
      'Registration Number':
        xmlDoc.getElementsByTagName('Registration_Number')[0]?.textContent || '',
      'Organization Number':
        xmlDoc.getElementsByTagName('Organization_Number')[0]?.textContent || '',
      'Last Name': xmlDoc.getElementsByTagName('Last_Name')[0]?.textContent || '',
      Address: xmlDoc.getElementsByTagName('Address')[0]?.textContent || '',
      Zip: xmlDoc.getElementsByTagName('Zip')[0]?.textContent || '',
      City: xmlDoc.getElementsByTagName('City')[0]?.textContent || '',
      'Body Type': xmlDoc.getElementsByTagName('Body_Type')[0]?.textContent || '',
      Year: xmlDoc.getElementsByTagName('Year')[0]?.textContent || '',
      Make: xmlDoc.getElementsByTagName('Make')[0]?.textContent || '',
      Model: xmlDoc.getElementsByTagName('Model')[0]?.textContent || '',
      VIN: xmlDoc.getElementsByTagName('VIN')[0]?.textContent || '',
      Colour: xmlDoc.getElementsByTagName('Colour')[0]?.textContent || '',
      'Insurance Company': xmlDoc.getElementsByTagName('Insurance_Company')[0]?.textContent || '',
      Weight: xmlDoc.getElementsByTagName('Weight')[0]?.textContent || '',
      Length: xmlDoc.getElementsByTagName('Length')[0]?.textContent || '',
      Width: xmlDoc.getElementsByTagName('Width')[0]?.textContent || '',
      'Date of First Registration':
        xmlDoc.getElementsByTagName('Date_Of_First_Registration')[0]?.textContent || '',
      'Fuel Type': xmlDoc.getElementsByTagName('Fuel_Type')[0]?.textContent || '',
      'Number of Axles in Operation':
        xmlDoc.getElementsByTagName('Number_Of_Axles_In_Operation')[0]?.textContent || '',
      'Total Number of Axles':
        xmlDoc.getElementsByTagName('Total_Number_of_Axles')[0]?.textContent || '',
      'Automatic Transmission':
        xmlDoc.getElementsByTagName('Automatic_Transmission')[0]?.textContent || '',
    };

    const carinfoJSON = JSON.stringify(vehicleData);
    localStorage.setItem('PCD_Info', carinfoJSON);
    const pcdData = vehicleData

    return pcdData;
  } catch (error) {
    console.error('Error converting XML data:', error);
    throw error;
  }
};
