import { Alert } from '@mui/material';
import React from 'react';

const NoCaseSuccess = () => {
  return (
    <Alert variant="filled" severity="success">
      This car have no resent cases
    </Alert>
  );
};

export default NoCaseSuccess;
