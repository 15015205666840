import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import BoxWrapper from '../../components/UI/Layout/FlexBox/BoxWrapper';
import ReviewPageAccordion from './ReviewPageAccordion';
import OpenSideBar from '../../components/Button/OpenSideBar';
import { useRsaCaseData } from '../../hooks/query/useRsaCaseData';
import CarLoading from '../../components/Loading/CarLoading';

const ReviewPage = () => {
  const { id } = useParams();
  const { data: caseData, isLoading, isError } = useRsaCaseData(id);

  return (
    <BoxWrapper
      content={
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" gutterBottom>
              Review Case
            </Typography>
            <OpenSideBar />
          </Box>
          {isLoading && <CarLoading state="loading" />}
          {isError && <CarLoading state="error" />}
          {!isLoading && !isError && caseData && <ReviewPageAccordion caseData={caseData} />}
        </>
      }
    />
  );
};

export default ReviewPage;
