// SplitLayout.jsx
import React from 'react';
import { Box, Tooltip, TextField, FormControl, Button } from '@mui/material';
import { flexBoxRow } from '../../../Styles/defaultStyle';

import RegistrationSearch from '../../../components/FormFields/RegistrationNumber/RegistrationSearch';
import CoverCauseSelector from '../../../components/FormFields/SelectOptions/CoverCauseSelector';
import ChannelSelect from '../../../components/FormFields/SelectOptions/ChannelSelect';
import PrioritySelect from '../../../components/FormFields/SelectOptions/PrioritySelect';
import PhoneNumber from '../../../components/FormFields/PhoneNumber/PhoneNumber';
import MapComponent from '../../../components/Map/MapComponent';
import SearchComponent from '../../../components/Map/Search/SearchComponent';
import SearchResultsDisplay from '../../../components/UI/UserFeedback/SearchResults/SearchResultsDisplay';

const SplitLayout = ({
  formik,
  searchResult,
  caseData,
  openCase,
  hasSearched,
  loading,
  pcdErrorMessage,
  rsaErrorMessage,
  handleSearchResult,
  handleInputChange,
  handleBlur,
  handleChannelChange,
  handlePriorityChange,
  handleSearch, // Include handleSearch
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <RegistrationSearch
          registrationNumber={formik.values.registrationNumber}
          onChange={handleInputChange}
          onSearch={handleSearch} // Use handleSearch here
          loading={loading}
          error={formik.touched.registrationNumber && formik.errors.registrationNumber}
        />
        {hasSearched && (
          <SearchResultsDisplay
            hasSearched={!!formik.values.pcdData}
            pcdData={formik.values.pcdData}
            pcdLoading={loading}
            caseLoading={loading}
            loading={loading}
            caseData={caseData}
            openCase={openCase}
            pcdErrorMessage={pcdErrorMessage}
            rsaErrorMessage={rsaErrorMessage}
          />
        )}
        <ChannelSelect
          value={formik.values.channel}
          onChange={handleChannelChange}
          error={formik.touched.channel && Boolean(formik.errors.channel)}
        />
        <Box sx={flexBoxRow}>
          <PhoneNumber
            value={formik.values.onSiteContact.phone}
            onChange={(e) => formik.setFieldValue('onSiteContact.phone', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.onSiteContact?.phone}
            touched={formik.touched.onSiteContact?.phone}
            countryCode={formik.values.onSiteContact.countryCode}
            onCountryCodeChange={(value) =>
              formik.setFieldValue('onSiteContact.countryCode', value)
            }
            fullWidth
            sx={{ flex: 1 }}
          />
          <Tooltip title="Contact name for the person on site" placement="top">
            <TextField
              id="contactName"
              name="onSiteContact.contactName"
              label="Contact at Location"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.onSiteContact?.contactName || ''}
              error={
                formik.touched.onSiteContact?.contactName &&
                Boolean(formik.errors.onSiteContact?.contactName)
              }
              helperText={
                formik.touched.onSiteContact?.contactName &&
                formik.errors.onSiteContact?.contactName
              }
              sx={{ flex: 1 }}
            />
          </Tooltip>
        </Box>
        <CoverCauseSelector
          value={formik.values.coverCause || ''}
          onChange={(value) => formik.setFieldValue('coverCause', value)}
          error={formik.touched.coverCause && Boolean(formik.errors.coverCause)}
          helperText={formik.touched.coverCause && formik.errors.coverCause}
        />
        <SearchComponent onSearchResult={handleSearchResult} />
        <Box sx={flexBoxRow}>
          <PrioritySelect
            value={formik.values.priority}
            onChange={handlePriorityChange}
            onBlur={handleBlur}
            error={formik.errors.priority}
            touched={formik.touched.priority}
            formikProps={formik}
          />
          <Tooltip title="Add message for station" placement="top">
            <FormControl variant="filled" fullWidth>
              <TextField
                variant="outlined"
                name="stationMessage"
                placeholder="Message to station"
                value={formik.values.stationMessage}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            </FormControl>
          </Tooltip>
        </Box>
        <Button type="submit" variant="contained" color="success">
          Create Case
        </Button>
      </Box>
      <Box sx={{ width: '600px', flexShrink: 0 }}>
        <MapComponent searchResult={searchResult} formikValues={formik.values} heighInput={'600px'}/>
      </Box>
    </Box>
  );
};

export default SplitLayout;
