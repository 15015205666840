import React from 'react';
import { Button } from '@mui/material';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { useLayout } from '../../Context/LayoutContext';
import { iconButtonSx } from './IconButtonSx';

const LayoutSwtich = () => {
  const { layout, toggleLayout } = useLayout();

  return (
    <Button onClick={toggleLayout} variant="contained" sx={iconButtonSx(46, 46)}>
      {layout === 'base' ? <VerticalSplitIcon /> : <ViewHeadlineIcon />}
    </Button>
  );
};

export default LayoutSwtich;
