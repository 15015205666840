import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

const SearchResultsList = ({ searchResults, onResultClick }) => {
  return (
    <List>
      {searchResults.map((result, index) => (
        <ListItem button key={index} onClick={() => onResultClick(result)}>
          <ListItemText
            primary={result.poi ? result.poi.name : (typeof result.address === 'string' ? result.address : result.address.freeformAddress)}
            secondary={result.poi ? result.poi.name : undefined}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SearchResultsList;
