const defaultInitialValues = {
    registrationNumber: '',
    selectedCountry: 'NO',
    pcdData: {
      address: '',
      bodyType: '',
      city: '',
      colour: '',
      dateOfFirstRegistration: '',
      fuelType: '',
      insuranceCompany: '',
      lastName: '',
      length: '',
      make: '',
      model: '',
      numberOfAxlesInOperation: '',
      organizationNumber: '',
      registrationNumber: '',
      totalNumberOfAxles: '',
      vin: '',
      weight: '',
      width: '',
      year: '',
      zip: '',
    },
    onSiteContact: {
      contactName: '',
      countryCode: '+47',
      phone: '',
    },
    channel: '',
    searchTerm: '',
    location: {
      locationMessage: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      countryCodeISO: '',
      lon: '',
      lat: '',
    },
    coverCause: '',
    caseComment: '',
    priority: '3',
    stationMessage: '',
    dateTime: null,
  };
  
  const mergedCaseInitialValues = (mergedCaseData) => ({
    registrationNumber: mergedCaseData.registrationNumber,
    carModel: mergedCaseData.vehicle?.vehicleMake || mergedCaseData.vehicle?.vehicleModel || '',
    insuranceCompany: mergedCaseData.vehicle?.vehicleInsurance || '',
    coverCause: mergedCaseData.coverCause,
    coverage: mergedCaseData.coverage,
    ownerName: mergedCaseData.account?.firstName
      ? `${mergedCaseData.account?.firstName} ${mergedCaseData.account?.lastName}`
      : mergedCaseData.account?.lastName,
    ownerBillingAddress: mergedCaseData.account?.billingStreet || '',
    ownerBillingCity: mergedCaseData.account?.billingCity || '',
    ownerPostalCode: mergedCaseData.account?.billingPostalCode || '',
    customerName: mergedCaseData.contact?.contactOneSiteName || '',
    customerNumber: mergedCaseData.contact?.contactMobile || '',
    location: mergedCaseData.location?.address || '',
    locationMessage: mergedCaseData.location?.message || '',
    station: mergedCaseData.station?.stationName || '',
    stationMessage: mergedCaseData.station?.stationMessage || '',
    workShopName: mergedCaseData.workshop?.name || '',
    workShopAddress: mergedCaseData.workshop?.address || '',
    caseComment: mergedCaseData.caseComment || '',
    priority: mergedCaseData.priority || '',
  });
  
  export const useInitialValues = (formType, mergedCaseData) => {
    const getInitialValues = () => {
      switch (formType) {
        case 'defaultForm':
          return defaultInitialValues;
        case 'mergedCaseForm':
          return mergedCaseInitialValues(mergedCaseData);
        default:
          return {};
      }
    };
  
    return { initialValues: getInitialValues() };
  };
  