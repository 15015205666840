import React from 'react';
import { Box, Typography } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';

const CaseStatusAlert = ({ data }) => {
  const { caseId, coverCause, lastModifiedDate } = data;
  const caselink = process.env.REACT_APP_MISSING_DATA + `${caseId}/view`;
  const date = new Date(lastModifiedDate);
  const formattedDate = date.toLocaleDateString('en-GB');

  return (
    <>
      <Typography variant="subtitle1">
        <>
          <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
                gap: 2,
                border: 1,
                borderColor: '#ff182a',
                backgroundColor: 'rgba(255, 24, 42, 0.2)',
                p: 0.87,
                borderRadius: 1,
                flexGrow: 1,
              }}
            >
              <FeedbackIcon color="error" />
              <Typography variant="subtitle1" component="span">
                <a href={caselink} target="_blank" rel="noopener noreferrer" className="customLink">
                  Date: {formattedDate} - Cover cause: {coverCause}
                </a>
              </Typography>
            </Box>
          </Box>
        </>
      </Typography>
    </>
  );
};

export default CaseStatusAlert;
