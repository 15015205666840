// phoneValidation.js

const phoneNumberFormat = {
    '+43': /^\d{9}$/, // Example: Austria (9 digits)
    '+32': /^\d{8,9}$/, // Belgium (8-9 digits)
    '+359': /^\d{9}$/, // Bulgaria (9 digits)
    '+385': /^\d{9}$/, // Croatia (9 digits)
    '+357': /^\d{8}$/, // Cyprus (8 digits)
    '+420': /^\d{9}$/, // Czech Republic (9 digits)
    '+45': /^\d{8}$/, // Denmark (8 digits)
    '+372': /^\d{7,8}$/, // Estonia (7-8 digits)
    '+358': /^\d{9,10}$/, // Finland (9-10 digits)
    '+33': /^\d{9}$/, // France (9 digits)
    '+49': /^\d{10,11}$/, // Germany (10-11 digits)
    '+30': /^\d{10}$/, // Greece (10 digits)
    '+36': /^\d{9}$/, // Hungary (9 digits)
    '+354': /^\d{7}$/, // Iceland (7 digits)
    '+353': /^\d{9,10}$/, // Ireland (9-10 digits)
    '+39': /^\d{9,10}$/, // Italy (9-10 digits)
    '+371': /^\d{8}$/, // Latvia (8 digits)
    '+370': /^\d{8}$/, // Lithuania (8 digits)
    '+352': /^\d{8,9}$/, // Luxembourg (8-9 digits)
    '+356': /^\d{8}$/, // Malta (8 digits)
    '+373': /^\d{8}$/, // Moldova (8 digits)
    '+377': /^\d{8}$/, // Monaco (8 digits)
    '+31': /^\d{9}$/, // Netherlands (9 digits)
    '+47': /^\d{8}$/, // Norway (8 digits)
    '+48': /^\d{9}$/, // Poland (9 digits)
    '+351': /^\d{9}$/, // Portugal (9 digits)
    '+40': /^\d{9}$/, // Romania (9 digits)
    '+7': /^\d{10}$/, // Russia (10 digits)
    '+378': /^\d{10}$/, // San Marino (10 digits)
    '+381': /^\d{9}$/, // Serbia (9 digits)
    '+421': /^\d{9}$/, // Slovakia (9 digits)
    '+386': /^\d{8}$/, // Slovenia (8 digits)
    '+34': /^\d{9}$/, // Spain (9 digits)
    '+46': /^\d{9,10}$/, // Sweden (9-10 digits)
    '+41': /^\d{9}$/, // Switzerland (9 digits)
    '+380': /^\d{9}$/, // Ukraine (9 digits)
    '+44': /^\d{10}$/, // United Kingdom (10 digits)
    '+972': /^\d{9}$/, // Israel (9 digits)
  };
  
  // Function to validate phone number based on country code
  export const validatePhoneNumber = (number, code) => {
    const pattern = phoneNumberFormat[code];
    if (pattern && !pattern.test(number)) {
      return `Invalid phone number format for ${code}`;
    }
    return '';
  };
  