import React from 'react';
import { Autocomplete, TextField, FormControl, Tooltip } from '@mui/material';
import { coverCauseOptions } from './options/coverCauseOptions';

const CoverCauseSelector = ({ value, onChange, error, helperText }) => {
  return (
    <Tooltip title="Select what has happened with the customer" placement="top">
      <FormControl fullWidth variant="outlined" error={error}>
        <Autocomplete
          options={coverCauseOptions}
          getOptionLabel={(option) => option.label}
          value={coverCauseOptions.find((option) => option.value === value) || null}
          onChange={(event, newValue) => {
            onChange(newValue ? newValue.value : '');
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a cover cause"
              variant="outlined"
              fullWidth
              error={error}
              helperText={helperText}
            />
          )}
        />
      </FormControl>
    </Tooltip>
  );
};

export default CoverCauseSelector;
