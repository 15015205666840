import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  InputAdornment,
  IconButton,
  Popper,
  Paper,
  Select,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchResultsList from './SearchResultList';
import useSearchHandler from '../../../hooks/useSearchHandler';
import { useInitialValues } from '../../../hooks/useInitialValues';

const SearchComponent = ({ onSearchResult }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputMode, setInputMode] = useState('search');

  const { handleSearch, handleSearchResultClick } = useSearchHandler(
    inputMode,
    setSearchResults,
    setAnchorEl,
    onSearchResult
  );

  const { initialValues } = useInitialValues('');

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      coordinates: '',
    },
    onSubmit: handleSearch,
  });

  const handleInputChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
    setAnchorEl(event.currentTarget);

    if (inputMode === 'search') {
      formik.handleSubmit();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  const handleModeChange = (event) => {
    setInputMode(event.target.value);
    setSearchResults([]);
    formik.resetForm();
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <TextField
          id={inputMode === 'search' ? 'searchTerm' : 'coordinates'}
          name={inputMode === 'search' ? 'searchTerm' : 'coordinates'}
          label={inputMode === 'search' ? 'Search for a location' : 'Enter coordinates (lat, lon)'}
          value={inputMode === 'search' ? formik.values.searchTerm : formik.values.coordinates}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  value={inputMode}
                  variant="standard"
                  onChange={handleModeChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select search mode' }}
                >
                  <MenuItem value="search">Search by Address</MenuItem>
                  <MenuItem value="coordinates">Coordinates</MenuItem>
                </Select>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
      {inputMode === 'search' && searchResults.length > 0 && (
        <Popper open={Boolean(searchResults.length)} anchorEl={anchorEl} placement="bottom-start">
          <Paper>
            <SearchResultsList
              searchResults={searchResults}
              onResultClick={handleSearchResultClick}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default SearchComponent;
