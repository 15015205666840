import React from 'react';
import { Box, Card } from '@mui/material';

const BoxWrapper = ({ content }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 2,
        mb: 3,
        p: 2,
        boxShadow: 5,
        borderRadius: 3,
        flexGrow: 1
      }}
      component={Card}
    >
      {content}
    </Box>
  );
};

export default BoxWrapper;
