export const countryToCurrency = {
  NOR: 'NOK',
  SWE: 'SEK',
  USA: 'USD',
  CAN: 'CAD',
  GBR: 'GBP',
  EUR: 'EUR',
  AUS: 'AUD',
  NZL: 'NZD',
  JPN: 'JPY',
  CHN: 'CNY',
  IND: 'INR',
  RUS: 'RUB',
  BRA: 'BRL',
  MEX: 'MXN',
  ZAF: 'ZAR',
  HKD: 'HKD',
  SGD: 'SGD',
  KRW: 'KRW',
  CHF: 'CHF',
  DKK: 'DKK',
  NOK: 'NOK',
  SEK: 'SEK',
  PLN: 'PLN',
  CZK: 'CZK',
  HUF: 'HUF',
  ILS: 'ILS',
  ARS: 'ARS',
  CLP: 'CLP',
  PEN: 'PEN',
  COP: 'COP',
  RON: 'RON',
  TRY: 'TRY',
};
