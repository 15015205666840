import React from 'react';
import { Link } from 'react-router-dom';
import { ListItemText, ListItemIcon } from '@mui/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  text-decoration: none;
  color: inherit;
  position: relative;
  margin-bottom: 10px;
  ${({ active }) =>
    active &&
    css`
      background-color: #ff182a;
      color: white;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -16px;
        height: 100%;
        width: calc(100% + 25px);
        background-color: #ff182a;
        z-index: -1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 6.5px;
        right: -10px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid #f1f1f1;
        transform: rotate(180deg);
      }
      border-radius: 5px;
    `};
`;

const ListItem = ({ to, icon: Icon, primary, active }) => (
  <StyledListItem as={Link} to={to} active={active}>
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
    <ListItemText primary={primary} />
  </StyledListItem>
);

export default ListItem;
