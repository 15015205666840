import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Typography, Box, Tooltip } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import DatePicker from '../../DatePicker/DatePicker';

const PrioritySelect = ({ value, onChange, onBlur, error, touched, formikProps }) => {
  return (
    <Tooltip title="Select priority" placement="top">
      <FormControl fullWidth variant="outlined" error={touched && Boolean(error)}>
        <InputLabel id="priority-select-label">Priority</InputLabel>
        <Select
          labelId="priority-select-label"
          id="priority-select"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label="Priority"
        >
          <MenuItem value="1" name="Umiddelbar">
            1. Umiddelbar
          </MenuItem>
          <MenuItem value="2" name="Høy">
            2. Høy
          </MenuItem>
          <MenuItem value="3" name="Normal">
            3. Normal
          </MenuItem>
          <MenuItem value="4" name="Lav">
            4. Lav
          </MenuItem>
          <MenuItem value="5" name="Forhåndsbestilling">
            5. Forhåndsbestilling
          </MenuItem>
        </Select>
        {value === '1' && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              This is only for police jobs.
            </Typography>
          </Box>
        )}
        {value === '2' && (
          <Box sx={{ display: 'flex', gap: 1, justifyItems: 'center', marginTop: 1 }}>
            <ReportGmailerrorredIcon color="error" />
            <Typography variant="body1" color="error">
              Contact station on phone after despatching case
            </Typography>
          </Box>
        )}
        {value === '5' && (
          <DatePicker
            formikValue={formikProps.values.dateTime}
            setFormikValue={(value) => formikProps.setFieldValue('dateTime', value)}
          />
        )}
      </FormControl>
    </Tooltip>
  );
};

export default PrioritySelect;
