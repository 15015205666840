import React from 'react';
import { Box, Drawer, FormControl, TextField, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const drawerWidth = 350;

const RightSidebar = ({ onClose }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={true}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#fafafa',
          boxShadow: 7,
          p: 2,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
      <Tooltip title="NB! The text will be deleted when this is closed">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
      </Box>
      <Box sx={{ overflow: 'none' }}>
      <Typography variant='caption' color='error'>*NB! The text will be deleted when this is close</Typography>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Case notes"
            name="caseComment"
            multiline
            rows={20}
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <Typography variant='caption' color='error'>*NB! The text will be deleted when this is close</Typography>
      </Box>
    </Drawer>
  );
};

export default RightSidebar;
