import React, { useState, useEffect } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  FormControl,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { countryCodes } from './countryCodes';
import { registrationFormat } from './registrationFormat';

const RegistrationSearch = ({ registrationNumber, onChange, onSearch, loading, error }) => {
  const [selectedCountry, setSelectedCountry] = useState(() => {
    const storedCountry = localStorage.getItem('regNumCountry');
    return storedCountry ? storedCountry : 'NO';
  });

  const [searchStatus, setSearchStatus] = useState('idle');
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    localStorage.setItem('regNumCountry', selectedCountry);
  }, [selectedCountry]);

  const validateRegistrationNumber = (number, country) => {
    const pattern = registrationFormat[country];
    if (pattern && !pattern.test(number)) {
      return `Invalid format for ${country}`;
    }
    return '';
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    onChange(e);
    if (searchStatus !== 'idle') {
      setSearchStatus('idle');
    }
    const validationError = validateRegistrationNumber(value, selectedCountry);
    setInputError(validationError);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    const validationError = validateRegistrationNumber(registrationNumber, e.target.value);
    setInputError(validationError);
  };

  const handleSearchClick = async () => {
    const validationError = validateRegistrationNumber(registrationNumber, selectedCountry);
    if (validationError) {
      setInputError(validationError);
      return;
    }
    try {
      setSearchStatus('loading');
      await onSearch(selectedCountry);
      setSearchStatus('idle');
    } catch (error) {
      setSearchStatus('error');
    }
  };

  return (
    <Tooltip title="Search on registration number" placement="top">
      <FormControl
        variant="outlined"
        fullWidth
        error={!!inputError || error}
        style={{ marginTop: 16 }}
      >
        <TextField
          variant="outlined"
          name="registrationNumber"
          placeholder="Enter registration number"
          value={registrationNumber.toLocaleUpperCase()}
          onChange={handleInputChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchClick()}
          error={!!inputError || !!error}
          helperText={inputError || error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextField
                  select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  variant="standard"
                >
                  {countryCodes.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      ({option.name}) {option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchClick} disabled={loading}>
                  {searchStatus === 'loading' ? (
                    <CircularProgress size={24} />
                  ) : searchStatus === 'error' ? (
                    <ErrorIcon color="error" />
                  ) : (
                    <SearchIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Tooltip>
  );
};

export default RegistrationSearch;