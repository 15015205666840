import React, { createContext, useState, useContext, useEffect } from 'react';

const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState('base');

  useEffect(() => {
    const storedLayout = localStorage.getItem('layout');
    if (storedLayout) {
      setLayout(storedLayout);
    }
  }, []);

  const toggleLayout = () => {
    setLayout((prevLayout) => {
      const newLayout = prevLayout === 'base' ? 'split' : 'base';
      localStorage.setItem('layout', newLayout);
      return newLayout;
    });
  };

  return (
    <LayoutContext.Provider value={{ layout, toggleLayout }}>
      {children}
    </LayoutContext.Provider>
  );
};
