import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';

const OwnerInfoDialog = ({ data, open, handleClose }) => {
  const {
    address,
    automaticTransmission,
    bodyType,
    city,
    colour,
    dateOfFirstRegistration,
    fuelType,
    insuranceCompany,
    firstName,
    lastName,
    companyName,
    length,
    make,
    model,
    numberOfAxlesInOperation,
    organizationNumber,
    registrationNumber,
    totalNumberOfAxles,
    vin,
    weight,
    width,
    zip,
  } = data;

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const isCompany = Boolean(organizationNumber);
  const displayCompanyName = isCompany ? lastName : companyName;

  const ownerRows = !isCompany
    ? [
        { property: 'First name', value: firstName },
        { property: 'Last name', value: lastName },
        { property: 'Company name', value: companyName },
        { property: 'Organization number', value: organizationNumber },
        { property: 'Address', value: address },
        { property: 'City', value: city },
        { property: 'Postal code', value: zip },
      ]
    : [
        { property: 'Company name', value: displayCompanyName },
        { property: 'Organization number', value: organizationNumber },
        { property: 'Address', value: address },
        { property: 'City', value: city },
        { property: 'Postal code', value: zip },
      ];

  const carRows = [
    { property: 'Registration number', value: registrationNumber },
    { property: 'Make', value: make },
    { property: 'Model', value: model },
    { property: 'Date of first registration', value: formatDate(dateOfFirstRegistration) },
    { property: 'Vin', value: vin },
    { property: 'Insurance company', value: insuranceCompany },
    { property: 'Body type', value: bodyType },
    { property: 'Fuel type', value: fuelType },
    { property: 'Weight', value: weight },
    { property: 'Width', value: width },
    { property: 'Length', value: length },
    { property: 'Colour', value: colour },
    { property: 'Total number of axles', value: totalNumberOfAxles },
    { property: 'Number of axles in operation', value: numberOfAxlesInOperation },
    { property: 'Automatic transmission', value: automaticTransmission },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">Owner Information</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 500 }} size="small" aria-label="car details">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <strong>Car details:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carRows.map((row) => (
                  <TableRow
                    key={row.property}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table sx={{ minWidth: 500 }} size="small" aria-label="owner details">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <strong>Owner details:</strong>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ownerRows.map((row) => (
                  <TableRow
                    key={row.property}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.property}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OwnerInfoDialog;
