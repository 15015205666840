import { useDataStore } from '../hooks/store/useDataStore';
import { xmlConverter } from '../utils/xmlConverter';

export const getRsaData = async (axiosInstance, searchValue) => {
  try {
    useDataStore.getState().clearRsaData();

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_RSA_API_BASE_URL}?registrationNumber=${encodeURIComponent(searchValue)}`
    );
    const rsaCarDetails = JSON.stringify(response.data);

    useDataStore.getState().setRsaCarDetails(rsaCarDetails);

    return rsaCarDetails;
  } catch (error) {
    if (error instanceof Error) {
      console.error('API call failed:', error.message);
      throw new Error(error.message || 'Failed to fetch RSA data');
    } else {
      console.error('Unknown error:', error);
      throw new Error('Failed to fetch RSA data');
    }
  }
};

export const getRsaCaseData = async (axiosInstance, searchValue) => {
  console.log('searchValue:', searchValue);
  try {
    useDataStore.getState().clearRsaData();

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_RSA_API_BASE_URL}?caseId=${encodeURIComponent(searchValue)}`
    );
    const rsaCaseDetails = JSON.stringify(response.data[0]);

    useDataStore.getState().setRsaCarDetails(rsaCaseDetails);

    return rsaCaseDetails;
  } catch (error) {
    if (error instanceof Error) {
      console.error('API call failed:', error.message);
      throw new Error(error.message || 'Failed to fetch RSA data');
    } else {
      console.error('Unknown error:', error);
      throw new Error('Failed to fetch RSA data');
    }
  }
};

export const postRsaCase = async (axiosInstance, body) => {
  const url = process.env.REACT_APP_RSA_API_BASE_URL;
  console.log('Request body:', body);

  try {
    const response = await axiosInstance.post(url, body);

    console.log('Request:', response);
    console.log('Response data:', response.data);

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('API call failed:', error.message);
      throw new Error(error.message || 'Failed to fetch RSA data');
    } else {
      console.error('Unknown error:', error);
      throw new Error('Failed to fetch RSA data');
    }
  }
};

// PCD
export const getPcdData = async (axiosInstance, searchValue, countryCode) => {
  try {
    useDataStore.getState().clearPcdData();

    const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
<Vehicle_Search_Parameters>
    <Registration_Number>${searchValue}</Registration_Number>
    <Country_Code>${countryCode}</Country_Code>
</Vehicle_Search_Parameters>`;

    const response = await axiosInstance.post(process.env.REACT_APP_PCD_API_BASE_URL, xmlData, {
      headers: {
        'Content-Type': 'application/xml',
      },
    });

    const pcdData = xmlConverter(response.data);

    return pcdData;
  } catch (error) {
    if (error instanceof Error) {
      console.error('API call failed:', error.message);
      throw new Error(error.message || 'Failed to fetch PCD data');
    } else {
      console.error('Unknown error:', error);
      throw new Error('Failed to fetch PCD data');
    }
  }
};
