import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './providers/AuthProvider';
import { queryClient } from './hooks/query/queryClient';
import { StrictMode } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
  <AuthProvider>
   <QueryClientProvider client={queryClient}>
    <App />
   </QueryClientProvider>
  </AuthProvider>
 </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
