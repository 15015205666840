export const coverCauseOptions = [
    { value: 'Abnormal noise from brakes', label: 'Abnormal noise from brakes' },
    { value: 'Abnormal noise from engine', label: 'Abnormal noise from engine' },
    { value: 'Abnormal noise from transmission', label: 'Abnormal noise from transmission' },
    { value: 'Air suspension broken', label: 'Air suspension broken' },
    { value: 'Battery', label: 'Battery' },
    { value: 'Bearing broken', label: 'Bearing broken' },
    { value: 'Brakes', label: 'Brakes' },
    { value: 'Brakes not connecting', label: 'Brakes not connecting' },
    { value: 'Brakes not disconnecting', label: 'Brakes not disconnecting' },
    { value: 'Broken spring', label: 'Broken spring' },
    { value: 'Collision', label: 'Collision' },
    { value: 'Collision with another vehicle', label: 'Collision with another vehicle' },
    { value: 'Collision with moose/deer', label: 'Collision with moose/deer' },
    { value: 'Collision with other animal', label: 'Collision with other animal' },
    { value: 'Collision with stationary object', label: 'Collision with stationary object' },
    { value: 'Damaged', label: 'Damaged' },
    { value: 'Does not start', label: 'Does not start' },
    { value: 'Does not start, has no electricity, at home address', label: 'Does not start, has no electricity, at home address' },
    { value: 'Does not start, has no electricity, away from home', label: 'Does not start, has no electricity, away from home' },
    { value: 'Door locks not opening', label: 'Door locks not opening' },
    { value: 'Driven off road, drivable', label: 'Driven off road, drivable' },
    { value: 'Driven off road, non-drivable', label: 'Driven off road, non-drivable' },
    { value: 'Electrical', label: 'Electrical' },
    { value: 'Engine', label: 'Engine' },
    { value: 'Engine running, car not moving', label: 'Engine running, car not moving' },
    { value: 'Breakdown', label: 'Breakdown' },
    { value: 'Exhaust pipe problem', label: 'Exhaust pipe problem' },
    { value: 'Fire', label: 'Fire' },
    { value: 'Fire when driving', label: 'Fire when driving' },
    { value: 'Fire when stationary', label: 'Fire when stationary' },
    { value: 'Fuel', label: 'Fuel' },
    { value: 'Headlights/other lights broken', label: 'Headlights/other lights broken' },
    { value: 'Heating/air con not working', label: 'Heating/air con not working' },
    { value: 'Hydraulics problem', label: 'Hydraulics problem' },
    { value: 'Ignition/steering lock stuck', label: 'Ignition/steering lock stuck' },
    { value: 'Keys', label: 'Keys' },
    { value: 'Keys broken', label: 'Keys broken' },
    { value: 'Keys inside locked car', label: 'Keys inside locked car' },
    { value: 'Keys inside locked car, engine running', label: 'Keys inside locked car, engine running' },
    { value: 'Keys lost', label: 'Keys lost' },
    { value: 'Leaking air', label: 'Leaking air' },
    { value: 'Leaking coolant', label: 'Leaking coolant' },
    { value: 'Leaking fuel', label: 'Leaking fuel' },
    { value: 'Leaking oil', label: 'Leaking oil' },
    { value: 'Lost electricity when driving', label: 'Lost electricity when driving' },
    { value: 'Lost power suddenly', label: 'Lost power suddenly' },
    { value: 'Malfunction of electrical device', label: 'Malfunction of electrical device' },
    { value: 'Out of fuel', label: 'Out of fuel' },
    { value: 'Problem connecting to trailer', label: 'Problem connecting to trailer' },
    { value: 'Puncture when stationary', label: 'Puncture when stationary' },
    { value: 'Puncture while driving', label: 'Puncture while driving' },
    { value: 'Smoke/Overheating', label: 'Smoke/Overheating' },
    { value: 'Stalled while driving', label: 'Stalled while driving' },
    { value: 'Steering', label: 'Steering' },
    { value: 'Steering went heavy suddenly', label: 'Steering went heavy suddenly' },
    { value: 'Stuck on road / driveway', label: 'Stuck on road / driveway' },
    { value: 'Suspension / chassis', label: 'Suspension / chassis' },
    { value: 'Tire', label: 'Tire' },
    { value: 'Trailer broken', label: 'Trailer broken' },
    { value: 'Transmission', label: 'Transmission' },
    { value: 'Transportation of drivable car', label: 'Transportation of drivable car' },
    { value: 'Transportation of non-drivable car', label: 'Transportation of non-drivable car' },
    { value: 'Vehicle - Engine damage', label: 'Vehicle - Engine damage' },
    { value: 'Vehicle - Fire', label: 'Vehicle - Fire' },
    { value: 'Vehicle - Liability', label: 'Vehicle - Liability' },
    { value: 'Vehicle - Off Road', label: 'Vehicle - Off Road' },
    { value: 'Vehicle - Theft/Vandalism', label: 'Vehicle - Theft/Vandalism' },
    { value: 'Vehicle - Wildlife accident', label: 'Vehicle - Wildlife accident' },
    { value: 'Warning lights', label: 'Warning lights' },
    { value: 'Warning lights Red', label: 'Warning lights Red' },
    { value: 'Warning lights Yellow', label: 'Warning lights Yellow' },
    { value: 'Wheel fell off', label: 'Wheel fell off' },
    { value: 'Windscreen / window broken', label: 'Windscreen / window broken' },
    { value: 'Wrong fuel', label: 'Wrong fuel' },
];
