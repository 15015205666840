import { useQuery } from '@tanstack/react-query';
import { getRsaCaseData } from '../../api/apiRequests';
import { useAxiosInstance } from '../../api/axiosInstance';

export const useRsaCaseData = (searchValue) => {
  const axiosInstance = useAxiosInstance();

  return useQuery({
    queryKey: ['rsaCaseData', searchValue],
    queryFn: async () => {
      if (!searchValue) return {};
      const response = await getRsaCaseData(axiosInstance, searchValue);
      return JSON.parse(response);
    },
    staleTime: 5 * 60 * 1000,
  });
};
