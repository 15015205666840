import React, { useState } from 'react';
import { Typography, Snackbar, Alert, Box } from '@mui/material';
import CasePageForm from './CasePageForm';
import { postRsaCase } from '../../api/apiRequests';
import BoxWrapper from '../../components/UI/Layout/FlexBox/BoxWrapper';
import OpenSideBar from '../../components/Button/OpenSideBar';
import { buildRequestBody } from '../../api/requestbody';
import { useNavigate } from 'react-router';
import { useAxiosInstance } from '../../api/axiosInstance';

const CasePage = () => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const axios = useAxiosInstance()

  const handleSubmit = async (values) => {
    console.log('handleSubmit called with values:', values); // Log the values received
    const body = buildRequestBody(values);

    try {
      // Send data to the server
      const response = await postRsaCase(axios, body);

      const caseId = response.id;

      if (caseId) {
        navigate(`/review/${caseId}`);
      }
    } catch (error) {
      console.error('Submission error:', error);
      setSnackbarMessage('Error submitting form');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason !== 'clickaway') setOpenSnackbar(false);
  };

  return (
    <BoxWrapper
      content={
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" gutterBottom>
              Create New Case
            </Typography>
            <OpenSideBar />
          </Box>
          <CasePageForm submitForm={handleSubmit} />
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      }
    />
  );
};

export default CasePage;
