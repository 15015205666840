import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import MissingFieldError from '../../UI/UserFeedback/ErrorHandeling/MissingFieldError';

const TextFieldWithValidation = ({
  name,
  label,
  value,
  errorCondition = false,
  helperText = '',
  caseId,
  caseNumber,
  isActive,
  ...rest
}) => {
  const [error, setError] = useState(false);
  const hasError = !value || errorCondition;

  const response = JSON.parse(localStorage.getItem('response'));
  const id = response?.caseId;

  useEffect(() => {
    setError(hasError);
  }, [value, errorCondition, hasError]);

  return (
    <TextField
      name={name}
      label={label}
      disabled
      value={value || ''}
      error={error}
      helperText={error ? <MissingFieldError label={label} id={id} /> : helperText}
      {...rest}
    />
  );
};

export default TextFieldWithValidation;
