// PageLayout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import LeftSidebar from './Sidebars/LeftSideBar/LeftSideBar';
import RightSidebar from './Sidebars/RightSideBar';
import { useSidebar } from '../../../Context/SidebarContext';

const PageLayout = () => {
  const { isSidebarOpen, closeSidebar } = useSidebar();

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Box>
        <LeftSidebar />
      </Box>
      <Box className="mainFlex" sx={{ display: 'flex', flexGrow: 1, width: '100%' }}>
        <Outlet />
      </Box>
      {isSidebarOpen && <RightSidebar onClose={closeSidebar} />}
    </Box>
  );
};

export default PageLayout;
