export const iconButtonSx = (width, height) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    '&:hover': {
      boxShadow: 10,
    },
    boxShadow: 5,
    width: width || 36, // Default to 36 if no width is provided
    height: height || 36, // Default to 36 if no height is provided
  });